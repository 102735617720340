import Img from 'gatsby-image';
import _has from 'lodash/has';
import PropTypes from 'prop-types';
import React from 'react';

import './assoc-representative.scss';

/**
 * Component for rendering a member representative.
 */
const ParagraphAssocRepresentative = (props) => {
    return (
        <article className="member-representative">
            {_has(props, 'r.photo.r.image.localFile') && (
                <Img
                    fluid={
                        props.r.photo.r.image.localFile.childImageSharp.fluid
                    }
                />
            )}
            <h3>{props.name}</h3>
            {props.title && <div className="title">{props.title}</div>}
            {props.description && props.description.processed && (
                <div
                    className="description"
                    dangerouslySetInnerHTML={{
                        __html: props.description.processed,
                    }}
                />
            )}
        </article>
    );
};

ParagraphAssocRepresentative.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.object,
    r: PropTypes.object,
};

export default ParagraphAssocRepresentative;
