import _has from 'lodash/has';
import PropTypes from 'prop-types';
import React from 'react';

import './paragraph-document.scss';

const ParagraphDocument = (props) => {
    return (
        <>
            <div className={props.className}>
                {_has(
                    props,
                    'r.field_p_document.r.field_media_file.localFile',
                ) && (
                    <div className="widget-document">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href={
                                props.r.field_p_document.r.field_media_file
                                    .localFile.publicURL
                            }>
                            {props.r.field_p_document.name}
                        </a>
                        <i className="bi bi-file-earmark-arrow-down"></i>
                    </div>
                )}
            </div>
        </>
    );
};

ParagraphDocument.propTypes = {
    r: PropTypes.object,
    className: PropTypes.string,
};

export default ParagraphDocument;
