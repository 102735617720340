import _has from 'lodash/has';
import PropTypes from 'prop-types';
import React from 'react';

import './paragraph-video.scss';

const ParagraphVideo = (props) => {
    return (
        <>
            <div className={props.className}>
                {_has(props, 'r.field_p_video.oembed_attributes.html') && (
                    <div className="widget-video">
                        <div
                            className="video-embed"
                            dangerouslySetInnerHTML={{
                                __html: props.r.field_p_video.oembed_attributes.html
                                    .replace(
                                        'youtube.com/embed/',
                                        'youtube-nocookie.com/embed/',
                                    ).replace(
                                        'feature=oembed',
                                        'feature=oembed&rel=0'
                                    ),
                            }}
                        />
                        {_has(props, 'r.field_p_video.copyright') && (
                            <div className="video-copyright">
                                {props.r.field_p_video.copyright}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

ParagraphVideo.propTypes = {
    r: PropTypes.object,
    className: PropTypes.string,
};

export default ParagraphVideo;
