import classNames from 'classnames';
import _get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';

// Paragraphs
import FieldWidgets from '../FieldWidgets/FieldWidgets';

const FieldSections = ({ sections }) => (
    <>
        {sections.map((section, i) => {
            section.key = `section-${i}`;
            let classList = _get(section, 'behavior_classes', []);
            classList = classList ?? [];
            classList.push({
                'paragraph--type-columns-one':
                    section['__typename'] === 'paragraph__columns_one',
                'paragraph--type-columns-two-equal':
                    section['__typename'] === 'paragraph__columns_two_equal',
                'paragraph--type-columns-two-asym-right':
                    section['__typename'] ===
                    'paragraph__columns_two_asym_right',
                'paragraph--type-columns-two-asym-left':
                    section['__typename'] ===
                    'paragraph__columns_two_asym_left',
                'paragraph--type-columns-three-equal':
                    section['__typename'] === 'paragraph__columns_three_equal',
            });

            const classes = classNames(classList);
            const asymClasses =
                classList.indexOf('section--asym-balanced') !== -1
                    ? { large: 'col-md-7', small: 'col-md-5' }
                    : { large: 'col-md-8', small: 'col-md-4' };

            switch (section['__typename']) {
                case 'paragraph__columns_one':
                    return (
                        <div key={section.key} className={classes}>
                            <div className="container-content">
                                {section.r.col1 && (
                                    <FieldWidgets widgets={section.r.col1} />
                                )}
                            </div>
                        </div>
                    );
                case 'paragraph__columns_two_equal':
                    return (
                        <div key={section.key} className={classes}>
                            <div className="container-content">
                                <div className="row">
                                    <div className="col-md-6">
                                        {section.r.col1 && (
                                            <FieldWidgets
                                                widgets={section.r.col1}
                                            />
                                        )}
                                    </div>
                                    <div className="col-md-6">
                                        {section.r.col2 && (
                                            <FieldWidgets
                                                widgets={section.r.col2}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                case 'paragraph__columns_two_asym_right':
                    return (
                        <div key={section.key} className={classes}>
                            <div className="container-content">
                                <div className="row">
                                    <div className={asymClasses.large}>
                                        {section.r.col1 && (
                                            <FieldWidgets
                                                widgets={section.r.col1}
                                            />
                                        )}
                                    </div>
                                    <div className={asymClasses.small}>
                                        {section.r.col2 && (
                                            <FieldWidgets
                                                widgets={section.r.col2}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                case 'paragraph__columns_two_asym_left':
                    return (
                        <div key={section.key} className={classes}>
                            <div className="container-content">
                                <div className="row">
                                    <div className={asymClasses.small}>
                                        {section.r.col1 && (
                                            <FieldWidgets
                                                widgets={section.r.col1}
                                            />
                                        )}
                                    </div>
                                    <div className={asymClasses.large}>
                                        {section.r.col2 && (
                                            <FieldWidgets
                                                widgets={section.r.col2}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                case 'paragraph__columns_three_equal':
                    return (
                        <div key={section.key} className={classes}>
                            <div className="container-content">
                                <div className="row">
                                    <div className="col-md-4">
                                        {section.r.col1 && (
                                            <FieldWidgets
                                                widgets={section.r.col1}
                                            />
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        {section.r.col2 && (
                                            <FieldWidgets
                                                widgets={section.r.col2}
                                            />
                                        )}
                                    </div>
                                    <div className="col-md-4">
                                        {section.r.col2 && (
                                            <FieldWidgets
                                                widgets={section.r.col3}
                                            />
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                default:
                    console.log(section['__typename']);
                    return '';
            }
        })}
    </>
);

FieldSections.propTypes = {
    sections: PropTypes.array,
    key: PropTypes.string,
};

export default FieldSections;
