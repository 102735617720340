import PropTypes from 'prop-types';
import React from 'react';

import './paragraph-text.scss';

const ParagraphText = (props) => {
    return (
        <>
            <div className={props.className}>
                {props.text && props.text.processed !== '' && (
                    <div
                        className="body-text"
                        dangerouslySetInnerHTML={{
                            __html: props.text.processed,
                        }}
                    />
                )}
            </div>
        </>
    );
};

ParagraphText.propTypes = {
    text: PropTypes.object,
    className: PropTypes.string,
};

export default ParagraphText;
