import PropTypes from 'prop-types';
import React from 'react';

// import './paragraph-text-image.scss'
import './paragraph-heading.scss';

const ParagraphHeading = (props) => {
    const HeadingTag = `${props.heading.size ?? 'h2'}`;
    return (
        <>
            <div className={props.className}>
                {props.heading && props.heading.text !== '' && (
                    <HeadingTag id={props.titleID}>
                        {props.heading.text}
                    </HeadingTag>
                )}
            </div>
        </>
    );
};

ParagraphHeading.propTypes = {
    heading: PropTypes.object,
    relationships: PropTypes.object,
    className: PropTypes.string,
};

export default ParagraphHeading;
