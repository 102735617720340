import PropTypes from 'prop-types';
import React from 'react';

import LinkField from '../../field/FieldLink/FieldLink';
import './paragraph-links.scss';

const ParagraphLinks = (props) => {
    return (
        <>
            <div className={props.className}>
                <ul>
                    {props.links.map((item, i) => {
                        item.key = `link-key-${i}`;
                        return (
                            <li key={item.key}>
                                <i className="bi bi-arrow-right-short"></i>
                                <LinkField link={item} />
                            </li>
                        );
                    })}
                </ul>
            </div>
        </>
    );
};

ParagraphLinks.propTypes = {
    links: PropTypes.array,
    className: PropTypes.string,
};

export default ParagraphLinks;
