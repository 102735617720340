import classNames from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import ParagraphAssocRepresentative from '../../paragraph/ParagraphAssocRepresentative/ParagraphAssocRepresentative';
import ParagraphDocument from '../../paragraph/ParagraphDocument/ParagraphDocument';
import ParagraphEmbed from '../../paragraph/ParagraphEmbed/ParagraphEmbed';
import ParagraphHeading from '../../paragraph/ParagraphHeading/ParagraphHeading';
import ParagraphImage from '../../paragraph/ParagraphImage/ParagraphImage';
import ParagraphLinks from '../../paragraph/ParagraphLinks/ParagraphLinks';
// Paragraphs
import ParagraphText from '../../paragraph/ParagraphText/ParagraphText';
import ParagraphVideo from '../../paragraph/ParagraphVideo/ParagraphVideo';

const FieldWidgets = ({ widgets }) => (
    <>
        {widgets.map((widget, i) => {
            // If the widget is already a React element we return it immediately.
            if (React.isValidElement(widget)) {
                return widget;
            }
            const typeName = _.get(
                widget,
                '__typename',
                _.get(widget, 'internal.type', undefined),
            );
            const datakey = `paragraph-${typeName}--${i}`;
            widget.datakey = datakey;

            if (widget.title) {
                const widgetTitle = widget.title.text
                    ? widget.title.text
                    : widget.title;
                const opt = { length: 64, omission: '' };
                widget.titleID = _.truncate(
                    _.kebabCase(_.deburr(_.trim(widgetTitle))),
                    opt,
                );
            }

            let classList = _.get(widget, 'behavior_classes', []);
            classList = classList ?? [];
            classList.push('paragraph');
            classList.push(typeName.replace('paragraph__', 'paragraph-type--'));
            const classes = classNames(classList);

            switch (typeName) {
                case 'paragraph__text':
                    return (
                        <ParagraphText
                            {...widget}
                            key={datakey}
                            className={classes}
                        />
                    );
                case 'paragraph__heading':
                    return (
                        <ParagraphHeading
                            {...widget}
                            key={datakey}
                            className={classes}
                        />
                    );
                case 'paragraph__image':
                    return (
                        <ParagraphImage
                            {...widget}
                            key={datakey}
                            className={classes}
                        />
                    );
                case 'paragraph__document':
                    return (
                        <ParagraphDocument
                            {...widget}
                            key={datakey}
                            className={classes}
                        />
                    );
                case 'paragraph__video':
                    return (
                        <ParagraphVideo
                            {...widget}
                            key={datakey}
                            className={classes}
                        />
                    );
                case 'paragraph__embed':
                    return (
                        <ParagraphEmbed
                            {...widget}
                            key={datakey}
                            className={classes}
                        />
                    );
                case 'paragraph__links':
                    return (
                        <ParagraphLinks
                            {...widget}
                            key={datakey}
                            className={classes}
                        />
                    );
                case 'paragraph__assoc_representative':
                    return (
                        <ParagraphAssocRepresentative
                            {...widget}
                            key={datakey}
                            className={classes}
                        />
                    );
                default:
                    console.log(typeName);
                    console.log(widget);
                    return '';
            }
        })}
    </>
);

FieldWidgets.propTypes = {
    widgets: PropTypes.array,
};

export default FieldWidgets;
