import Img from 'gatsby-image';
import _has from 'lodash/has';
import PropTypes from 'prop-types';
import React from 'react';

import './paragraph-image.scss';

const ParagraphImage = (props) => (
    <>
        <div className={props.className}>
            {_has(
                props,
                'r.field_p_image.r.field_media_image.localFile.childImageSharp.fluid',
            ) && (
                <div className="widget-image">
                    <Img
                        fluid={
                            props.r.field_p_image.r.field_media_image.localFile
                                .childImageSharp.fluid
                        }
                    />
                </div>
            )}
            {props.text && props.text.processed !== '' && (
                <div
                    className="image-caption"
                    dangerouslySetInnerHTML={{ __html: props.text.processed }}
                />
            )}
        </div>
    </>
);

ParagraphImage.propTypes = {
    text: PropTypes.object,
    r: PropTypes.object,
    className: PropTypes.string,
};

export default ParagraphImage;
