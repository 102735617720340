import PropTypes from 'prop-types';
import React from 'react';

import LinkField from '../../field/FieldLink/FieldLink';
import './paragraph-embed.scss';

const ParagraphEmbed = (props) => (
    <>
        <div className={props.className}>
            {props.title && props.title.text !== '' && (
                <h2 id={props.titleID}>{props.title.text}</h2>
            )}
            {props.code && props.code.processed !== '' && (
                <div
                    className="embed-code"
                    dangerouslySetInnerHTML={{ __html: props.code.processed }}
                />
            )}
            <LinkField
                link={props.link}
                linkClasses={['btn', 'btn-secondary', 'embed__cta']}
            />
        </div>
    </>
);

ParagraphEmbed.propTypes = {
    link: PropTypes.object,
    code: PropTypes.object,
    title: PropTypes.object,
    className: PropTypes.string,
};

export default ParagraphEmbed;
